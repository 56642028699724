import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'culturettt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router : Router,

    ) { }
    
  

  navigateTo (slug){
    this.router.navigate(['/'+slug]);
  }
  ngOnInit(): void {

  }


}
