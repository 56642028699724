<!--Grid row-->
<div class="container-fluid" *transloco="let t">
  <div class="row">
    <div class="col">
      <!-- <multisite-carousel [interval]="interval" [carouselItems]="carouselItems" [showIndicators]="true"
        [controls]="false" [showCaptions]="true" [noPause]="true" [imageUrlTransformations]="'c_limit,w_1000'"
        [imageInlineStyles]="'filter:brightness(50%); display: block; width: 100%;'">
      </multisite-carousel> -->
      <div class="text-center mt-5 pt-5">
        <h1 class="display-3">For the ever-improving intercultural trainer</h1>
        <p class="lead w-75 text-center mx-auto mt-3">
          For coaching, training, counselling and consulting on cross-cultural issues, you'll find resources here to
          save
          you time, get a competitive edge and keep your skills sharp.
        </p>

      </div>
    </div>
  </div>

  <div class="container subhero-cards">
    <div class="row">
      <div class="card-group mt-5 pt-5">
        <div class="card">
          <img class="card-img-top"
            src="https://res.cloudinary.com/cebt/image/upload/w_700/v1674224217/concepts/cultureconnector-coaching-screen-device-training-session_kyml0m.jpg"
            alt="CultureConnector">
          <div class="card-body">
            <div class="text">
              <h2 class="card-title">CultureConnector</h2>
              <p class="card-text">Learn how to use the platform in training, coaching, consultancy and in growing your
                intercultural training business.</p>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start mt-2">
              <button [routerLink]="['/cultureconnector']" type="button" class="btn btn-primary">{{t('common.explore')}}</button>
              <button [routerLink]="['/cultureconnector/tips']" type="button" class="btn btn-primary">{{t('content.get_tips')}}</button>
            </div>
          </div>
        </div>
        <div class="card">
          <img class="card-img-top"
            src="https://res.cloudinary.com/cebt/image/upload/w_700/v1674243951/concepts/meditating-woman-sitting-in-pose-of-lotus-against-clear-sky-outdoors-SBI-300731584_ie8o5r.jpg"
            alt="Intercultural Pendulum">
          <div class="card-body">
            <div class="text">
              <h2 class="card-title">Intercultural Pendulum</h2>
              <p class="card-text">Update your knowledge on this new approach to coaching clients in transit between
                cultures.</p>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start mt-2">
              <button [routerLink]="['/icpendulum']" type="button" class="btn btn-primary">{{t('common.explore')}}</button>
              <button [routerLink]="['/icpendulum/tips']" type="button" class="btn btn-primary">{{t('content.get_tips')}}</button>
            </div>
          </div>
        </div>
        <div class="card">
          <img class="card-img-top"
            src="https://res.cloudinary.com/cebt/image/upload/w_700/v1674244040/concepts/portrait-of-a-businesswoman-presenting-something-on-a-meeting-SBI-300855659_tlejzt.jpg"
            alt="More tools">
          <div class="card-body">
            <div class="text">
              <h2 class="card-title">More tools</h2>
              <p class="card-text">The Intercultural Toolbox can also boost your intercultural training work. Find out
                how the toolbox and CultureTTT can be part of your professional development</p>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start mt-2">
              <button [routerLink]="['/coming-soon']" type="button"
                class="btn btn-primary">IC Toolbox</button>
              <button [routerLink]="['/coming-soon']" type="button" class="btn btn-primary">CultureTTT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="jumbotron my-5">
      <h3 class="display-4">Fuel for your professional journey</h3>
      <p class="mt-2">You have a plan to build on your career success so far. We hope you take your next step here. Intercultural work demands energy, passion, knowledge, wisdom, connection, empathy, speed and ingenuity. We're not selling those things here. We aim to help you find them inside yourself, through community, knowledge-sharing, collaborations and pooled resources. Try us and bring your intercultural magic to the party.
      </p>
      <!-- <hr class="my-4"> -->
      <!-- <div class="row partners">
        <div class="col-md-3">
          <img
            src="https://raymetrics.com/wp-content/uploads/2021/01/5c7121203f8bb20f5623ca81_invision-logo-export-v1.png"
            alt="">
        </div>
        <div class="col-md-3">
          <img src="https://pngimg.com/uploads/amazon/amazon_PNG28.png" alt="">
        </div>
        <div class="col-md-3">
          <img src="https://www.freepnglogos.com/uploads/vmware-png-logo/partners-login-vmware-png-logo-10.png" alt="">
        </div>
        <div class="col-md-3">
          <img src="https://www.seekpng.com/png/full/535-5354088_coca-cola-coca-cola-coca-cola-gray-logo.png" alt="">
        </div>
      </div> -->
    </div>
  </div>
  <!-- <div class="row">
    <div class="col">



      <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-6">
            <img
              src="https://res.cloudinary.com/cebt/image/upload/w_700/v1674224217/concepts/cultureconnector-coaching-screen-device-training-session_kyml0m.jpg"
              class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold lh-1 mb-3">CultureConnector</h1>
            <p class="lead">Learn how to use the platform in training, coaching, consultancy and in growing your
              intercultural training business.</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start mt-2">
              <button [routerLink]="['/cultureconnector']" type="button" class="btn btn-primary">Explore</button>
              <button [routerLink]="['/cultureconnector/tips']" type="button" class="btn btn-primary">Get tips</button>
            </div>
          </div>
        </div>
      </div>

      <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold lh-1 mb-3">Intercultural Pendulum</h1>
            <p class="lead">Update your knowledge on this new approach to coaching clients in transit between cultures.
            </p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start mt-2">
              <button [routerLink]="['/icpendulum']" type="button" class="btn btn-primary">Explore</button>
              <button [routerLink]="['/icpendulum/tips']" type="button" class="btn btn-primary">Get tips</button>
            </div>
          </div>
          <div class="col-10 col-sm-8 col-lg-6">
            <img
              src="https://res.cloudinary.com/cebt/image/upload/w_700/v1674243951/concepts/meditating-woman-sitting-in-pose-of-lotus-against-clear-sky-outdoors-SBI-300731584_ie8o5r.jpg"
              class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
          </div>
        </div>
      </div>

      <div class="b-example-divider"></div>

      <div class="container my-5">
        <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
          <div class="col-lg-7 p-3 p-lg-5 pt-lg-3">
            <h1 class="display-4 fw-bold lh-1">More tools</h1>
            <p class="lead">The Intercultural Toolbox can also boost your intercultural training work. Find out how the
              toolbox and CultureTTT can be part of your professional development.</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3 mt-2">
              <button [routerLink]="['/']" routerLinkActive="router-link-active" type="button"
                class="btn btn-primary">IC Toolbox</button>
              <button [routerLink]="['/']" type="button" class="btn btn-primary">CultureTTT</button>
            </div>
          </div>
          <div class="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
            <img class="rounded-lg-3"
              src="https://res.cloudinary.com/cebt/image/upload/w_700/v1674244040/concepts/portrait-of-a-businesswoman-presenting-something-on-a-meeting-SBI-300855659_tlejzt.jpg"
              alt="" width="720">
          </div>
        </div>
      </div>

    </div>
  </div> -->
</div>
<!--Grid row -->