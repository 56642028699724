import { Component, OnInit } from '@angular/core';
import { AuthService, LogoutService } from '@frontend/common';
import { ActivityLogService } from '@frontend/core';

import { User } from '@frontend/common';
import { MainNavigationLink } from '@frontend/common';

@Component({
  selector: 'app-ttt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Culture TTT';

  logoutObs;
  user: User = null;
  userSubscription;
  isAuthenticated: boolean = false;
  loading = false;
  error = null;
  sidenavMode : string = 'slim';
  mainNavigationLinks: MainNavigationLink[];
  brandLogoBanner: string; // temporary solution TODO move this logic elsewhere

  constructor(
    private authService: AuthService,
    private activityLogService: ActivityLogService,
    private logoutService : LogoutService,
  ) {
    this.mainNavigationLinks = [
      {labelText: null, labelLanguageKey : 'platform.pendulum', routerLinkValue: ['/icpendulum'], condition: ''},
      {labelText: null, labelLanguageKey : 'platform.cco', routerLinkValue: ['/cultureconnector'], condition: ''},
      // {labelText: null, labelLanguageKey : 'navigation.lesson', routerLinkValue: ['/design/13'], condition: 'hideIfNoUser'},
      // {labelText: null, labelLanguageKey : 'navigation.lesson', routerLinkValue: ['/design/13'], condition: 'hideIfNotAdmin'},
    ];
  }

  logout() {
    this.loading = true;
    this.logoutObs = this.logoutService.logout();
    this.logoutObs.subscribe(
      (response) => {
        this.loading = false;
      },
      (error) => {
        this.error = error;
        this.loading = false;
      }
    );
  }
  
  getUser() {
    this.loading = true;
    this.error = null;

    let getUserSub = this.authService.getUser(null).subscribe(
      () => {
        this.loading = false;
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.loading = false;
      }
    );
  }
  changeSidenavMode (newMode : string) {
    newMode = newMode ? newMode : this.sidenavMode === 'wide' ? 'slim' : 'wide';
    this.sidenavMode = newMode;
  };
  ngOnInit() {
    this.userSubscription = this.authService.user.subscribe(
      (user) => {
        this.user = user;
        this.isAuthenticated = !!user;
        this.activityLogService.logDefaultSiteVisit();
      },
      (error) => {
        this.activityLogService.logDefaultSiteVisit();
      }
    );
    if (this.authService.token) {
      this.getUser();
    }    
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.logoutObs.unsubscribe();
  }
}
